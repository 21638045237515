<template>
  <v-sheet
    color="white"
    class="mb-5"
    elevation="1"
    @keyup.enter="$emit('buscar')"
  >
    <v-container fluid>
      <v-row class="pa-3">
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="1"
        >
          <v-menu
            v-model="menuDataInicio"
            :nudge-right="40"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{}">
              <v-text-field
                v-model="dataInicioFormatada"
                v-mask="'##/##/####'"
                label="Data Início"
                dense
                hide-details="auto"
                outlined
                append-icon="mdi-calendar"
                tabindex="1"
                @focus="atualizar('atualizarAutomaticamente', false)"
                @blur="
                  atualizarFiltros(
                    'data_inicio',
                    formatarDataApi($event.target.value)
                  )
                "
                @click:append="menuDataInicio = true"
              />
            </template>
            <v-date-picker
              :value="value.campos.data_inicio"
              no-title
              class="ma-0"
              @input="atualizarFiltrosData('data_inicio', $event)"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="2"
        >
          <v-menu
            ref="menuHoraInicio"
            v-model="menuHoraInicio"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{}">
              <v-text-field
                v-mask="'##:##:##'"
                :value="value.campos.hora_inicio"
                label="Hora Início"
                outlined
                dense
                hide-details="auto"
                append-icon="mdi-clock"
                tabindex="2"
                @focus="atualizar('atualizarAutomaticamente', false)"
                @input="atualizarFiltros('hora_inicio', $event)"
                @click:append="menuHoraInicio = true"
              />
            </template>
            <v-time-picker
              :value="value.campos.hora_inicio"
              class="ma-0"
              no-title
              use-seconds
              format="24hr"
              @input="atualizarFiltros('hora_inicio', $event)"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="3"
        >
          <v-menu
            v-model="menuDataFim"
            :nudge-right="40"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{}">
              <v-text-field
                v-model="dataFimFormatada"
                v-mask="'##/##/####'"
                label="Data Fim"
                dense
                hide-details="auto"
                outlined
                append-icon="mdi-calendar"
                tabindex="3"
                @focus="atualizar('atualizarAutomaticamente', false)"
                @blur="
                  atualizarFiltros(
                    'data_fim',
                    formatarDataApi($event.target.value)
                  )
                "
                @click:append="menuDataFim = true"
              />
            </template>
            <v-date-picker
              :value="value.campos.data_fim"
              no-title
              class="ma-0"
              @input="atualizarFiltrosData('data_fim', $event)"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="4"
        >
          <v-menu
            ref="menuHoraFim"
            v-model="menuHoraFim"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{}">
              <v-text-field
                v-mask="'##:##:##'"
                :value="value.campos.hora_fim"
                label="Hora Fim"
                outlined
                dense
                hide-details="auto"
                append-icon="mdi-clock"
                tabindex="4"
                @focus="atualizar('atualizarAutomaticamente', false)"
                @input="atualizarFiltros('hora_fim', $event)"
                @click:append="menuHoraFim = true"
              />
            </template>
            <v-time-picker
              :value="value.campos.hora_fim"
              class="ma-0"
              no-title
              use-seconds
              format="24hr"
              @input="atualizarFiltros('hora_fim', $event)"
            />
          </v-menu>
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="5"
        >
          <v-text-field
            :value="value.campos.marca"
            outlined
            dense
            autocomplete="off"
            hide-details="auto"
            clearable
            label="Marca"
            tabindex="5"
            @input="atualizarFiltros('marca', $event)"
          />
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="7"
        >
          <v-select
            :value="value.campos.cor_id"
            :items="corVeiculos"
            item-text="nome_portugues"
            item-value="id"
            label="Cor do Veículo"
            hide-details="auto"
            :loading="carregandoTiposCores"
            clearable
            outlined
            dense
            tabindex="6"
            @focus="atualizar('atualizarAutomaticamente', false)"
            @input="atualizarFiltros('cor_id', $event)"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1 d-flex align-center"
          order="11"
        >
          <v-row>
            <v-col
              lg="4"
              class="pr-2 pr-sm-1"
              v-b-tooltip.hover="textoPaginacao"
            >
              <v-text-field
                v-mask="'###'"
                :value="value.quantidadePaginacao"
                dense
                class="inputPrice"
                outlined
                hide-details="auto"
                type="number"
                tabindex="11"
                @input="atualizar('quantidadePaginacao', $event)"
              />
            </v-col>

            <v-col lg="8" class="pl-2 pl-sm-1 d-flex align-center">
              <v-sheet color="grey lighten-3">
                <v-row no-gutters>
                  <v-col
                    cols="6"
                    v-b-tooltip.hover
                    title="Ativar atualização automática da página"
                  >
                    <v-switch
                      :input-value="value.atualizarAutomaticamente"
                      dense
                      hide-details
                      :false-value="false"
                      :true-value="true"
                      class="mt-1"
                      tabindex="12"
                      @change="atualizar('atualizarAutomaticamente', $event)"
                    />
                  </v-col>

                  <v-col
                    cols="6"
                    v-b-tooltip.hover="textoAtualizacao"
                  >
                    <v-text-field
                      v-mask="'#####'"
                      :value="value.segundosAtualizar"
                      dense
                      outlined
                      min="5"
                      hide-details="auto"
                      :color="timeToRefreshIsValid ? '' : 'red'"
                      type="number"
                      tabindex="13"
                      @input="atualizar('segundosAtualizar', $event)"
                    />
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="8"
        >
          <v-select
            :value="value.campos.tipo_veiculo_id"
            :items="tiposVeiculos"
            item-text="descricao"
            item-value="id"
            label="Tipo de Veículo"
            hide-details="auto"
            :loading="carregandoTiposVeiculos"
            clearable
            multiple
            outlined
            dense
            tabindex="7"
            @focus="atualizar('atualizarAutomaticamente', false)"
            @input="atualizarFiltros('tipo_veiculo_id', $event)"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.descricao }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ value.campos.tipo_veiculo_id.length - 1 }} outros)
              </span>
            </template>
          </v-select>
        </v-col>
        <v-hover v-slot="{ hover }" :open-delay="300">
          <v-col
            cols="6"
            sm="6"
            md="6"
            lg="2"
            xl="2"
            class="pa-2 pa-lg-1"
            order="6"
            order-lg="9"
          >
            <v-text-field
              id="placa"
              v-mask="'XXXXXXX'"
              :value="value.campos.placa"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
              clearable
              label="Placa"
              tabindex="8"
              @focus="atualizar('atualizarAutomaticamente', false)"
              @input="atualizarFiltros('placa', $event)"
            />
            <v-fade-transition>
              <span class="placasModal" v-if="hover">
                <pre class="linha1"> Placa ou palavra         Funcionalidade (considerando demais filtros)</pre>
                <pre class="linha2">               <span>+</span>                   Placas identificadas pelo sistema</pre>
                <pre class="linha3">               <span>-</span>                    Placas não identificadas pelo sistema</pre>
                <pre class="linha4">          ABC123<span>?</span>            Somente placas lidas pelo sistema assumindo qualquer</pre>
                <pre class="linha5">          A<span>?</span>C<span>?</span>234            letra ou número do lugar da <span>?</span></pre>
                <pre class="linha6">            ABC<span>*</span>                Somente placas lidas pelo sistema assumindo qualquer</pre>
                <pre class="linha7">            ABC<span>*</span>34             sequência de letras ou números no lugar do <span>*</span></pre>
                <pre class="linha8">            A<span>*</span>C<span>*</span>4</pre>
                <pre class="linha9">  ABC1234<span>,</span>EFG5678     Buscar multiplas placas usando vírgula</pre>
              </span>
            </v-fade-transition>
          </v-col>
        </v-hover>
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="9"
        >
          <v-select
            :value="value.campos.grupos_ponto_captura"
            :items="gruposPontosCaptura"
            item-text="descricao"
            item-value="id"
            label="Grupos Ponto Captura"
            hide-details="auto"
            :loading="carregandoGruposPontosCaptura"
            clearable
            multiple
            outlined
            dense
            tabindex="9"
            @focus="atualizar('atualizarAutomaticamente', false)"
            @input="atualizarFiltros('grupos_ponto_captura', $event)"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.descricao }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ value.campos.grupos_ponto_captura.length - 1 }} outros)
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="10"
        >
          <treeselect
            :load-options="buscarCameras"
            :options="camerasPorPontoCaptura"
            :auto-load-root-options="false"
            :multiple="true"
            placeholder="Câmeras"
            loadingText="Buscando câmeras..."
            clearAllText="Limpar todos"
            retryText="Recarregar"
            retryTitle="Clique para tentar novamente"
            v-model="camerasSelecionadas"
            @input="atualizarCamerasSelecionadas"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
          md="3"
          lg="1"
          xl="1"
          class="pa-2 pa-lg-1 d-flex justify-center"
          order="12"
        >
          <v-btn
            v-if="!selecionarLeituras"
            icon
            v-b-tooltip.hover="textoSelecao"
            @click="selecionarLeituras = true"
          >
            <v-icon
              v-text="'mdi-select-multiple'"
            />
          </v-btn>
          <v-btn
            v-if="selecionarLeituras"
            icon
            small
            v-b-tooltip.hover.topleft="textoTodasLeituras"
            class="mt-md-1"
            @click="$emit('selecionar-varias-leituras')"
          >
            <v-icon
              v-text="'mdi-checkbox-multiple-outline'"
            />
          </v-btn>
          <v-btn
            v-if="selecionarLeituras"
            icon
            v-b-tooltip.hover.topleft="textoProximidade"
            small
            class="mt-md-1 teste"
            @click="$emit('relatorio-proximidade')"
          >
            <v-icon
              v-text="'mdi-car-3-plus'"
            />
          </v-btn>
          <v-btn
            v-if="selecionarLeituras"
            icon
            small
            v-b-tooltip.hover.topleft="textoCancelar"
            class="mt-md-1"
            @click="$emit('cancelar-selecionar-leituras')"
          >
            <v-icon
              v-text="'mdi-close'"
            />
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          sm="3"
          md="3"
          lg="1"
          xl="1"
          class="pa-2 pa-lg-1"
          order="12"
        >
          <v-btn
            class="mt-1 buscar-btn"
            color="primary"
            outlined
            :loading="carregando"
            tabindex="14"
            @click="$emit('buscar')"
          >
            <v-icon class="botao-icone" left v-text="'mdi-magnify'"/>
            Buscar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-progress-linear
      v-if="carregando"
      color="primary"
      absolute
      indeterminate
    />
  </v-sheet>
</template>

<script>
  import apiTiposVeiculos from "@/api/tipos-veiculos";
  import apiTiposCores from "@/api/cores";
  import apiGruposPontosCaptura from "@/api/grupos-pontos-captura";
  import apiCameras from "@/api/cameras";
  import formatarData from "@/utils/formatar-data-input";

  import { Treeselect, LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'

  export default {
    components: {
      Treeselect
    },
    props: {
      value: {
        type: Object,
        default: () => {
          return {};
        },
      },
      carregando: {
        type: Boolean,
        default: false,
      },
      totalLeiturasSelecionadas: {
        type: Number,
        default: 0,
      },

      minTimeToRefesh: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        textoPaginacao: "Números de leituras por página",
        textoSelecao: "Seleção de leituras",
        textoCancelar: "Cancelar leituras",
        textoTodasLeituras: "Selecionar todas as leituras da página",
        textoProximidade: "Relatório de proximidade",
        hover: false,
        menuDataInicio: false,
        menuHoraInicio: false,
        menuDataFim: false,
        menuHoraFim: false,
        dataInicioFormatada: null,
        dataFimFormatada: null,
        carregandoPontosCaptura: false,
        carregandoTiposVeiculos: false,
        carregandoTiposCores: false,
        carregandoGruposPontosCaptura: false,
        pontosCaptura: [],
        cameras: [],
        tiposVeiculos: [],
        corVeiculos: [],
        gruposPontosCaptura: [],
        camerasSelecionadas: [],
        camerasPorPontoCaptura: null,
      };
    },
    computed: {
      selecionarLeituras: {
        get() {
          return this.value.selecionarLeituras;
        },
        set(val) {
          this.atualizar("selecionarLeituras", val);
        },
      },

      timeToRefreshIsValid() {
        if (!this.value.segundosAtualizar) {
          this.$emit('change-time-to-refresh-status', false)
          return false
        }

        if (Number(this.value.segundosAtualizar) >= this.minTimeToRefesh) {
          this.$emit('change-time-to-refresh-status', true)
          return true
        }
      },

      textoAtualizacao() {
        return `Tempo de atualização da página (mínimo: ${this.minTimeToRefesh})`
      }
    },
    watch: {
      "value.campos.data_inicio": {
        immediate: true,
        handler(data) {
          this.dataInicioFormatada = formatarData.ptBr(data);
        },
      },
      "value.campos.data_fim": {
        immediate: true,
        handler(data) {
          this.dataFimFormatada = formatarData.ptBr(data);
        },
      },
      cameras: {
        immediate: true,
        handler(data) {
          this.value.campos.cameras = data
        }
      }
    },
    mounted() {
      this.buscarTiposVeiculos();
      this.buscarGruposPontosCaptura();
      this.buscarCores();
    },
    methods: {
      ajustarItensSelectCameras() {
        let itens = [];
        this.pontosCaptura.forEach(function(pontoCaptura) {
          let item = {
            id: 'pc-' + pontoCaptura.id,
            label: pontoCaptura.descricao,
            children: []
          };

          let camerasPontoCaptura = [];
          pontoCaptura.cameras.forEach(function(camera) {
            let cameraPontoCaptura = {
              id: 'c-' + camera.id,
              label: camera.descricao,
            };

            camerasPontoCaptura.push(cameraPontoCaptura);
          });

          item.children = camerasPontoCaptura;
          itens.push(item);
        });

        this.camerasPorPontoCaptura = itens;
        this.camerasSelecionadas = [];
        this.cameras = []; //- Deixa em branco, pois quando não existem câmeras no filtro, leituras de todas as cameras serão buscadas
      },

      adicionarCamerasDoPontoDeCapturaAoFiltro(idPontoCaptura) {
        let i = 0;
        for (i = 0; i < this.pontosCaptura.length; i++) {
          let pontoCaptura = this.pontosCaptura[i];

          if (pontoCaptura.id !== idPontoCaptura) {
            continue;
          }

          let j = 0;
          for (j = 0; j < pontoCaptura.cameras.length; j++) {
            this.cameras.push(pontoCaptura.cameras[j].id);
          }

          break;
        }
      },

      atualizarCamerasSelecionadas() {
        this.cameras = [];

        if (this.camerasSelecionadas.length === 0) {
          //- Quando não existem cameras no filtro, todas elas já serão filtradas
          return;
        }

        let i = 0;
        for (i = 0; i < this.camerasSelecionadas.length; i++) {
          let item = this.camerasSelecionadas[i];
          let dadosItem = item.split('-');
          let tipoItem = dadosItem[0];
          let idItem = parseInt(dadosItem[1]);

          if (tipoItem === 'pc') this.adicionarCamerasDoPontoDeCapturaAoFiltro(idItem)
          else if (tipoItem === 'c') this.cameras.push(idItem)
        }
      },

      atualizar(key, value) {
        this.$emit("input", {...this.value, [key]: value});
      },

      atualizarFiltros(chave, valor) {
        if (!valor) {
          delete this.value.campos[chave];
          return;
        }
        const filtros = {
          ...this.value.campos,
          [chave]: valor,
        };

        this.$emit("input", {...this.value, campos: filtros});
      },

      atualizarFiltrosData(chave, valor) {
        this.atualizarFiltros(chave, valor);
        this.menuDataInicio = false;
        this.menuDataFim = false;
      },
      async buscarCores() {
        try {
          this.carregandoTiposCores = true;
          const resposta = await apiTiposCores.buscarCores({por_pagina: 1000});
          this.corVeiculos = resposta.data.data;
        } catch (e) {
          this.$snackbar.mostrar({
            cor: "warning",
            mensagem: "Erro ao buscar os tipos de cores",
          });
        } finally {
          this.carregandoTiposCores = false;
        }
      },

      async buscarTiposVeiculos() {
        try {
          this.carregandoTiposVeiculos = true;
          const resposta = await apiTiposVeiculos.listar({por_pagina: 1000});
          this.tiposVeiculos = resposta.data.tipos_veiculo;
        } catch (e) {
          this.$snackbar.mostrar({
            cor: "warning",
            mensagem: "Erro ao buscar os tipos de veículos",
          });
        } finally {
          this.carregandoTiposVeiculos = false;
        }
      },

      async buscarGruposPontosCaptura() {
        try {
          this.carregandoGruposPontosCaptura = true;
          const resposta = await apiGruposPontosCaptura.listar({
            por_pagina: 1000,
          });
          this.gruposPontosCaptura = resposta.data.grupos_ponto_captura;
        } catch (e) {
          this.$snackbar.mostrar({
            cor: "warning",
            mensagem: "Erro ao buscar os grupos dos pontos de captura",
          });
        } finally {
          this.carregandoGruposPontosCaptura = false;
        }
      },

      async buscarCameras({ action }) {
        if (action === LOAD_ROOT_OPTIONS) {
          try {
            const resposta = await apiCameras.listarAgrupadoPorPontoCaptura();
            this.pontosCaptura = resposta.data;
            this.ajustarItensSelectCameras()
          } catch (e) {
            throw new Error('Erro ao buscar.');
          }
        }
      },

      formatarDataApi(data) {
        return formatarData.api(data);
      },
    },
  };
</script>

<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
<style>
  .vue-treeselect__control {
    border-color: #9e9e9e;
    height: 40px;
  }
  .vue-treeselect__control-arrow {
    color: rgba(0, 0, 0, 0.54)!important;
    width: 8px!important;
  }
  .vue-treeselect__placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
  .vue-treeselect__placeholder, .vue-treeselect__single-value {
    top: auto;
    bottom: auto;
    line-height: 100%;
    padding-top: 3px;
  }
  .vue-treeselect--multi .vue-treeselect__input {
    padding: 0;
  }
</style>
<style scoped>
  .inputPrice >>> input[type="number"] {
    -moz-appearance: textfield;
  }

  .inputPrice >>> input::-webkit-outer-spin-button,
  .inputPrice >>> input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .placa {
    position: relative;
  }

  pre {
    font-family: system-ui;
    margin: 0;
  }

  .placasModal {
    position: absolute;
    background-color: #d7d7d7;
    height: 255px;
    left: -50%;
    top: 45px;
    padding: 1em;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
    color: #363636;
    border-radius: 5px;
    z-index: 500;
  }

  .linha1,
  .linha3,
  .linha6,
  .linha7,
  .linha8 {
    background-color: white;
  }

  .placasModal pre span {
    color: red;
  }

  .placasModal pre {
    font-size: 1em;
    padding: 0.3em;
  }

  .linha1 {
    color: white;
    background-color: #363636;
  }

  .linha4,
  .linha5,
  .linha6,
  .linha7,
  .linha8 {
    padding: 0 !important;
  }

  .linha4,
  .linha6 {
    padding-top: 0.3em !important;
    padding-left: 0.3em !important;
    padding-right: 0.3em !important;
  }

  .linha5,
  .linha8,
  .linha9 {
    padding-bottom: 0.3em !important;
    padding-left: 0.3em !important;
    padding-right: 0.3em !important;
  }

  @media (max-width: 959px) {
    .buscar {
      justify-content: center;
    }

    .placasModal {
      display: none;
    }
  }

  @media (max-width: 1264) {
    .placasModal {
      left: -20%;
      top: 50
    }
  }

  @media (min-width: 1264px) and (max-width: 1400px) {
    .buscar-btn {
      width: 80px !important;
    }

    .botao-icone {
      margin: 0 !important;
    }
  }

  .btn__close__filters {
    font-weight: 800;
    font-size: 22px;
    text-decoration: none;
  }
</style>
