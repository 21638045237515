import axios from '@/plugins/axios'

export default {

  async listar (dados) {
    const parametros = new URLSearchParams(dados).toString()
    return await axios.get(`cameras?${parametros}`)
  },

  async listarAgrupadoPorPontoCaptura () {
    return await axios.get('cameras/listar-agrupado-por-ponto-de-captura')
  },

  async buscar (id) {
    return await axios.get(`cameras/${id}`)
  },

  async inserir (dados) {
    if ('status' in dados) dados.status = Number(dados.status)

    return await axios.post('cameras', dados)
  },

  async atualizar (id, dados) {
    if ('status' in dados) dados.status = Number(dados.status)

    return await axios.put(`cameras/${id}`, dados)
  },

  async deletar (id) {
    return await axios.delete(`cameras/${id}`)
  },

}
